import React from 'react'

const LogoPng = (props) =>(
    <svg xmlns="http://www.w3.org/2000/svg"
    width={140}
    height={80}
    version="1.1"
    viewBox="0.00 0.00 200.00 120.00"
    {...props}
    >
<path fill="#063a71" d="
  M 82.27 48.10
  C 83.85 52.24 84.98 55.13 86.25 60.18
  Q 87.66 65.81 92.10 69.94
  Q 92.55 70.36 92.73 71.79
  Q 92.80 72.32 93.33 72.30
  L 96.56 72.15
  Q 96.99 72.13 96.99 72.56
  L 96.97 87.54
  Q 96.97 88.28 97.71 88.19
  Q 98.66 88.07 98.90 88.88
  Q 99.04 89.38 98.56 89.56
  Q 96.36 90.37 94.68 89.20
  Q 94.37 88.99 94.04 89.18
  C 84.98 94.73 82.90 74.90 94.37 77.73
  Q 95.10 77.91 94.89 77.19
  Q 94.58 76.08 94.88 74.98
  A 1.50 1.49 -23.8 0 0 92.11 73.91
  Q 91.54 75.02 89.79 74.85
  C 87.65 74.65 85.17 71.13 84.03 69.48
  Q 83.70 69.00 83.36 69.48
  C 78.70 76.24 76.59 76.38 71.87 69.55
  Q 71.55 69.09 71.21 69.53
  C 69.28 72.07 65.91 77.14 62.81 73.62
  C 53.26 62.72 60.59 53.30 67.78 44.27
  C 69.07 42.65 68.88 38.26 69.25 36.57
  Q 70.29 31.77 73.96 28.44
  A 0.81 0.80 -28.0 0 0 74.20 27.66
  C 72.51 20.52 63.73 22.47 59.51 25.77
  C 44.93 37.13 42.96 71.46 42.96 88.32
  C 42.96 92.89 38.04 93.21 34.92 92.03
  Q 34.44 91.85 34.17 91.41
  Q 25.65 77.56 22.58 61.92
  C 20.01 48.79 21.40 32.94 23.72 19.50
  C 24.08 17.45 26.11 16.17 28.03 17.30
  C 30.10 18.52 30.96 22.76 32.43 25.61
  Q 32.71 26.17 33.06 25.64
  C 34.43 23.53 37.39 17.79 39.60 16.94
  C 42.72 15.73 43.54 20.41 43.95 23.01
  Q 44.88 28.86 45.35 34.77
  Q 45.42 35.58 45.76 34.85
  C 49.22 27.45 54.10 20.28 62.06 17.81
  C 70.45 15.20 78.30 18.31 80.28 27.45
  Q 80.38 27.94 80.76 28.26
  Q 85.82 32.67 86.10 39.27
  C 86.20 41.73 86.29 43.00 87.88 45.00
  C 90.33 48.09 98.66 57.92 96.51 62.06
  C 95.12 64.75 92.23 64.12 91.23 61.47
  Q 88.34 53.83 82.52 47.94
  Q 82.01 47.43 82.27 48.10
  Z
  M 39.37 46.81
  Q 39.38 47.07 39.57 47.25
  Q 39.95 47.61 39.92 47.08
  L 39.02 28.72
  Q 38.95 27.22 38.23 28.54
  L 35.66 33.28
  Q 35.41 33.74 35.57 34.24
  Q 37.52 40.33 39.33 46.46
  Q 39.36 46.57 39.37 46.81
  Z
  M 28.47 31.81
  Q 28.39 31.58 28.32 31.82
  C 27.02 36.47 27.32 42.20 27.23 47.01
  C 26.97 60.00 30.37 72.36 36.55 83.61
  Q 37.03 84.49 37.10 83.49
  C 38.49 65.79 34.57 48.62 28.47 31.81
  Z
  M 74.85 37.89
  A 0.96 0.96 0.0 0 0 75.72 39.23
  L 79.24 39.25
  A 0.96 0.96 0.0 0 0 80.13 37.91
  L 78.38 33.84
  A 0.96 0.96 0.0 0 0 76.62 33.83
  L 74.85 37.89
  Z
  M 65.87 66.93
  C 67.63 64.60 68.38 62.92 69.08 59.82
  Q 70.62 52.98 73.66 46.73
  A 0.18 0.18 0.0 0 0 73.36 46.54
  C 69.01 52.19 61.88 59.17 65.20 66.85
  Q 65.46 67.46 65.87 66.93
  Z
  M 78.17 53.39
  A 0.56 0.56 0.0 0 0 77.11 53.39
  L 74.61 60.87
  A 0.56 0.56 0.0 0 0 74.62 61.26
  L 77.09 67.15
  A 0.56 0.56 0.0 0 0 78.12 67.15
  L 80.64 61.08
  A 0.56 0.56 0.0 0 0 80.65 60.69
  L 78.17 53.39
  Z
  M 94.8907 81.7503
  A 2.47 2.47 0.0 0 0 92.4467 79.2545
  L 91.6267 79.2460
  A 2.47 2.47 0.0 0 0 89.1310 81.6900
  L 89.0893 85.6697
  A 2.47 2.47 0.0 0 0 91.5333 88.1655
  L 92.3533 88.1740
  A 2.47 2.47 0.0 0 0 94.8490 85.7300
  L 94.8907 81.7503
  Z"
/>
<path fill="#063a71" d="
  M 49.69 88.35
  L 52.60 88.14
  A 1.56 1.56 0.0 0 0 52.68 85.03
  Q 49.79 84.67 47.70 82.78
  Q 47.30 82.41 47.26 81.87
  C 46.93 77.33 52.37 76.30 55.03 78.13
  A 1.67 1.67 0.0 0 1 55.43 80.50
  Q 53.91 82.54 53.44 79.67
  A 0.50 0.49 -4.8 0 0 52.95 79.26
  L 50.53 79.24
  A 1.38 1.38 0.0 0 0 50.05 81.92
  C 51.88 82.58 54.39 83.07 55.36 84.69
  C 56.66 86.85 55.28 89.08 52.99 89.72
  Q 50.47 90.43 47.81 89.70
  Q 47.25 89.55 47.25 88.97
  L 47.26 86.73
  A 0.74 0.74 0.0 0 1 48.72 86.54
  L 49.09 87.92
  Q 49.21 88.38 49.69 88.35
  Z"
/>
<path fill="#063a71" d="
  M 62.09 78.38
  Q 62.52 78.65 62.98 78.42
  C 70.80 74.52 70.27 83.15 69.93 87.47
  Q 69.89 87.92 70.28 88.13
  L 71.43 88.77
  A 0.50 0.50 0.0 0 1 71.19 89.71
  L 66.50 89.77
  Q 65.92 89.78 66.04 89.22
  Q 66.20 88.46 66.94 88.15
  Q 67.52 87.91 67.52 87.29
  L 67.50 80.96
  A 1.51 1.51 0.0 0 0 66.16 79.46
  Q 64.05 79.23 62.60 80.71
  A 1.33 1.30 67.6 0 0 62.23 81.63
  L 62.23 87.33
  Q 62.23 87.82 62.64 88.11
  L 63.64 88.80
  A 0.51 0.51 0.0 0 1 63.35 89.73
  L 58.41 89.77
  A 0.55 0.55 0.0 0 1 58.11 88.75
  L 59.39 87.93
  Q 59.76 87.69 59.76 87.24
  L 59.76 79.93
  A 0.65 0.64 3.5 0 0 59.19 79.29
  L 58.33 79.19
  A 0.69 0.69 0.0 0 1 58.25 77.83
  Q 60.35 77.33 62.09 78.38
  Z"
/>
<path fill="#063a71" d="
  M 77.6762 90.0682
  A 6.43 5.70 100.7 0 1 73.2691 82.6917
  A 6.43 5.70 100.7 0 1 80.0638 77.4318
  A 6.43 5.70 100.7 0 1 84.4709 84.8083
  A 6.43 5.70 100.7 0 1 77.6762 90.0682
  Z
  M 82.00 82.20
  A 2.89 2.89 0.0 0 0 79.11 79.31
  L 78.67 79.31
  A 2.89 2.89 0.0 0 0 75.78 82.20
  L 75.78 85.32
  A 2.89 2.89 0.0 0 0 78.67 88.21
  L 79.11 88.21
  A 2.89 2.89 0.0 0 0 82.00 85.32
  L 82.00 82.20
  Z"
/>
<path fill="#063a71" d="
  M 107.45 79.30
  L 105.17 80.57
  Q 104.70 80.83 104.70 81.36
  L 104.71 87.50
  A 0.71 0.71 0.0 0 0 105.25 88.18
  L 107.01 88.59
  A 0.57 0.57 0.0 0 1 106.88 89.72
  L 100.78 89.71
  A 0.48 0.47 32.8 0 1 100.58 88.80
  L 102.18 88.08
  Q 102.54 87.92 102.54 87.52
  L 102.53 80.14
  Q 102.53 79.71 102.14 79.51
  L 100.55 78.71
  A 0.49 0.49 0.0 0 1 100.77 77.78
  L 103.65 77.77
  Q 104.31 77.76 104.41 78.41
  L 104.51 78.97
  A 0.19 0.18 -28.6 0 0 104.83 79.06
  Q 106.70 77.07 109.48 77.62
  Q 110.00 77.72 110.00 78.24
  L 110.00 80.48
  A 0.74 0.74 0.0 0 1 108.55 80.71
  L 108.20 79.60
  A 0.52 0.52 0.0 0 0 107.45 79.30
  Z"
/>
<path fill="#063a71" d="
  M 115.7739 90.0467
  A 6.41 5.67 100.3 0 1 111.3414 82.7262
  A 6.41 5.67 100.3 0 1 118.0661 77.4333
  A 6.41 5.67 100.3 0 1 122.4986 84.7538
  A 6.41 5.67 100.3 0 1 115.7739 90.0467
  Z
  M 116.8755 88.3595
  A 4.62 3.34 90.8 0 0 120.2797 83.7866
  A 4.62 3.34 90.8 0 0 117.0045 79.1205
  A 4.62 3.34 90.8 0 0 113.6003 83.6934
  A 4.62 3.34 90.8 0 0 116.8755 88.3595
  Z"
/>
<path fill="#063a71" d="
  M 128.14 90.23
  L 128.18 92.98
  A 0.59 0.58 4.2 0 0 128.67 93.54
  L 130.32 93.82
  Q 130.74 93.89 130.74 94.32
  L 130.74 94.74
  Q 130.73 95.21 130.26 95.21
  L 124.74 95.21
  Q 124.28 95.21 124.26 94.75
  Q 124.22 93.64 125.41 93.65
  A 0.65 0.64 -0.4 0 0 126.06 93.00
  L 126.02 80.16
  Q 126.01 79.68 125.58 79.49
  L 124.08 78.80
  A 0.49 0.49 0.0 0 1 124.17 77.87
  Q 126.25 77.38 128.12 78.20
  Q 128.73 78.46 129.31 78.14
  C 138.06 73.38 140.36 92.22 128.67 89.80
  Q 128.13 89.69 128.14 90.23
  Z
  M 134.1212 81.8369
  A 2.45 2.45 0.0 0 0 131.7100 79.3487
  L 130.6701 79.3324
  A 2.45 2.45 0.0 0 0 128.1819 81.7436
  L 128.1188 85.7631
  A 2.45 2.45 0.0 0 0 130.5300 88.2513
  L 131.5699 88.2676
  A 2.45 2.45 0.0 0 0 134.0581 85.8564
  L 134.1212 81.8369
  Z"
/>
<path fill="#063a71" d="
  M 142.47 90.50
  L 142.46 93.05
  A 0.63 0.63 0.0 0 0 143.05 93.68
  L 144.32 93.76
  Q 144.97 93.80 145.01 94.45
  Q 145.04 94.81 144.79 95.04
  Q 144.59 95.22 144.18 95.22
  Q 141.40 95.24 139.04 95.18
  A 0.72 0.71 -46.8 0 1 139.00 93.75
  L 139.72 93.68
  Q 140.30 93.62 140.30 93.03
  L 140.27 80.09
  Q 140.27 79.59 139.81 79.42
  L 138.38 78.90
  A 0.52 0.52 0.0 0 1 138.40 77.91
  Q 140.54 77.23 142.25 78.31
  Q 142.83 78.68 143.39 78.29
  C 151.03 73.02 155.67 91.02 143.06 89.97
  Q 142.47 89.92 142.47 90.50
  Z
  M 142.49 80.64
  L 142.53 86.98
  A 1.33 1.33 0.0 0 0 143.86 88.30
  L 144.95 88.29
  A 3.74 3.52 89.7 0 0 148.45 84.53
  L 148.45 83.01
  A 3.74 3.52 89.7 0 0 144.91 79.29
  L 143.82 79.30
  A 1.33 1.33 0.0 0 0 142.49 80.64
  Z"
/>
<path fill="#063a71" d="
  M 155.15 84.46
  C 155.51 88.37 159.07 89.29 162.01 87.23
  A 0.56 0.56 0.0 0 1 162.85 87.50
  L 163.00 87.94
  Q 163.17 88.43 162.71 88.66
  C 149.64 95.12 150.34 75.11 160.00 77.71
  Q 163.57 78.67 163.21 83.61
  A 0.41 0.41 0.0 0 1 162.80 84.00
  L 155.57 84.00
  Q 155.11 84.00 155.15 84.46
  Z
  M 155.58 82.31
  L 160.76 82.21
  A 0.18 0.18 0.0 0 0 160.94 82.03
  L 160.93 81.93
  A 2.76 2.76 0.0 0 0 158.12 79.22
  L 158.10 79.22
  A 2.76 2.76 0.0 0 0 155.39 82.03
  L 155.40 82.13
  A 0.18 0.18 0.0 0 0 155.58 82.31
  Z"
/>
<path fill="#063a71" d="
  M 169.85 78.78
  Q 170.98 77.93 171.73 77.73
  C 178.41 75.95 177.01 83.88 177.02 87.47
  Q 177.02 87.91 177.41 88.12
  L 178.74 88.83
  A 0.49 0.48 -30.9 0 1 178.51 89.74
  L 173.68 89.75
  A 0.57 0.57 0.0 0 1 173.38 88.70
  L 174.42 88.06
  A 0.77 0.76 75.0 0 0 174.79 87.40
  L 174.72 80.72
  A 0.96 0.95 -78.2 0 0 174.17 79.87
  Q 171.50 78.63 169.55 80.81
  Q 169.22 81.18 169.22 81.67
  L 169.23 87.29
  Q 169.23 87.77 169.63 88.04
  L 170.79 88.84
  A 0.49 0.49 0.0 0 1 170.52 89.73
  L 165.71 89.76
  A 0.70 0.70 0.0 0 1 165.57 88.38
  L 166.47 88.21
  Q 167.03 88.09 167.03 87.53
  L 167.01 79.94
  Q 167.01 79.36 166.42 79.35
  Q 165.55 79.33 165.08 78.72
  Q 164.79 78.33 165.23 78.12
  Q 167.93 76.80 169.37 78.72
  Q 169.58 78.99 169.85 78.78
  Z"
/>
</svg>
)

export default LogoPng