import { useDispatch } from "react-redux";
import {fetchStart,fetchFail, fetchEnd, loadData} from '../features/evenemangSlice';
import useAxios, { axiosPublic } from "./useAxios";
import {toastSuccessNotify, toastErrorNotify} from '../helper/ToastNotify';

const useEvenemangCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();


    const getEvenemangs = async()=>{
      dispatch(fetchStart())
      try {
          const {data} = await axiosWithToken.get('api/evenemang/');
          dispatch(loadData(data))
      } catch (error) {
          dispatch(fetchFail())
          console.log(error)
      }
  }

    const addEvenemang = async(info)=>{
        dispatch(fetchStart())
        try {
            await axiosPublic.post('api/evenemang/', info);
            //getEvenemangs();
            dispatch(fetchEnd());
        } catch (error) {
            dispatch(fetchFail());
            toastErrorNotify(error.response.statusText);
            console.log(error)
        }
    }

    const deleteParticipant = async(id) => {
      try {
        await axiosWithToken.delete(`api/evenemang/${id}/`)
        toastSuccessNotify(`participant with this ${id} successfuly deleted.`)
        getEvenemangs();
    } catch (error) {
        console.log(error);
        toastErrorNotify(`message with this ${id} can not be deleted.`)
    }
    }

  return {
    getEvenemangs,
    addEvenemang,
    deleteParticipant
  }
}

export default useEvenemangCall