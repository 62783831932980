import React, {useEffect} from 'react';
import BoardsCard from '../components/BoardsCard';
import { useSelector } from 'react-redux';
import useAboutCall from '../hooks/useAboutCall';
import useBoardCall from '../hooks/useBoardCall';
import {Helmet} from 'react-helmet';


const About = () => {

  const {aboutData} = useSelector((state) => state.about)
  const {boardData} = useSelector((state) => state.board)
  const {getAbout} =useAboutCall()
  const {getBoard} =useBoardCall()

  useEffect(() => {
    getAbout()
    getBoard()
  }, [])
  

  //console.log(boardData)

  const h2Style = {
    "fontFamily":"Muli-SemiBold",
    "textTransform":"uppercase",
    "letterSpacing": "7px",
    "border": "1px solid #dad8e4",
    "display": "block",
    "padding": "0 10px",
    "marginBottom": "20px",
    "marginTop": "10px",
    "borderRadius": "6px"
  }


  return (
    <>
    <Helmet>
      <title>Om Oss</title>
      <meta name='description' content='Snödroppen Kultur Förening - Om Oss - About us' />
    </Helmet>
    <div className="container mt-100">
      <div className="row">
      <div className="col-md-12">
        <h2 style={h2Style} align="center">{aboutData[0]?.visionTitle}</h2>
        <p>{aboutData[0]?.vision}</p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <h2 style={h2Style} variant="h4" color="primary" align="center">{aboutData[0]?.missionTitle}</h2>
        <p>{aboutData[0]?.mission}</p>
      </div>
    </div>
    <div className="row">
      <h2 style={h2Style} align="center">{aboutData[0]?.boardTitle}</h2>
      {boardData?.map((board) =>{
        return(
      <div key={board.id} className="col-md-4 d-flex justify-content-evenly mb-4">
        
       
          
            <BoardsCard  board={board}/>
         
      </div> )
        })}
    </div>
    </div>
    </>
  )
}

export default About