import React, {useEffect} from 'react'
import MyCalendar from '../components/MyCalendar';
import { useSelector } from 'react-redux';
import useEventCall from '../hooks/useEventCall';
import { Helmet } from 'react-helmet';

const Calendar = () => {
    const {events} = useSelector((state) => state.event);
    const {getEvents} = useEventCall();

    useEffect(() => {
      getEvents()
    }, [])
    

  return (
    <>
    <Helmet>
      <title>Kalender</title>
      <meta name='description' content='Det är Snödroppen Kultur Förening Kalender sida - för att hitta ett evenemang -  This is the calender page' />
    </Helmet>
    <div className='container mt-100'>
        <MyCalendar events={events}/>
    </div>
    </>
  )
}

export default Calendar