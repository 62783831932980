import React from 'react';
import { Helmet } from 'react-helmet';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import MembershipForm, { memberSchema } from "../components/forms/MembershipForm";

import useMemberCall from "../hooks/useMemberCall";


const BecomeMember = () => {
  const {addMember} = useMemberCall();
  return (
    <>
    <Helmet>
      <title>Medlemskap</title>
      <meta name='description' content='Bli medlem - Become a member - Medlemskap page för Snödroppen Kultur Förening - This is the membership page' />
    </Helmet>
    <Container maxWidth="lg" className='mt-100'>
      <Grid
        container
        justifyContent="center"
        direction="row-reverse"
        sx={{
          p: 2,
        }}
      >
        <Grid item xs={12} align={'center'} mb={3}>
          <Typography style={{
            "fontFamily":"Muli-SemiBold",
            "textTransform":"uppercase",
            "letterSpacing": "7px",
            "border": "1px solid #dad8e4",
            "display": "inline-block",
            "padding": "0 10px"
          }} variant="h3" color="primary" align="center">
            BLI MEDLEM
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6}>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              birthday: "",
              gender: "",
              phone_number: "",
              address: "",
              zip_code: "",
              city: "",
              terms_condition:false}}
            validationSchema={memberSchema}
            onSubmit={(values, actions) => {
              addMember(values);
              actions.resetForm();
              actions.setSubmitting(false);
            }}
            component={(props) => <MembershipForm {...props} />}
          ></Formik>
        </Grid>
      </Grid>
    </Container>
    </>
  );
};

export default BecomeMember